<template>
  <transition name="fade">
    <new-message-mobile v-if="isUnReadMessage" />
  </transition>
  <template v-if="type != 'empty'">
    <!-- <div class="fx">
      <ul class="fxhead">
        <li class="in" :class="{'on': $route.meta.gnbMenu === 'charge'}"><a @click="goPageByName('charge')"><em></em><span>{{$t('front.common.deposit')}}</span></a></li>
        <li class="out" :class="{'on': $route.meta.gnbMenu === 'exchange'}"><a @click="goPageByName('exchange')"><em></em><span>{{$t('front.common.withdrawal')}}</span></a></li>
        <li class="home"><a @click="goPageByName('mobileMain')"><em></em><span>홈</span></a></li>
        <li class="bet" :class="{'on': $route.meta.gnbMenu === 'betList'}"><a @click="goPageByName('betList')"><em></em><span>{{$t('front.mypage.betList')}}</span></a></li>
        <li class="cs" :class="{'on': $route.meta.gnbMenu === 'cs'}"><a @click="goPageByName('qnaList')"><em></em><span>{{$t('front.common.cscenter')}}</span></a></li>
      </ul>
    </div> -->
    <!-- <header>
      <div class="header">
        <h1 class="logo"><a @click="goPageByName('mobileMain')"><img src="@/assets/img/play_peanut.svg" /></a></h1>
        <div class="logininfowrap" v-if="userData">
          <ul class="mym">
            <li class="myc"><img src="@/assets_mobile/img/mycicon.png" /><span>{{userData.cashAmt ? thousand(userData.cashAmt.toString()): 0}}</span></li>
            <li class="myp" @click="goPageByName('point')"><img src="@/assets_mobile/img/mypicon.png" /><span>{{userData.pointAmt ? thousand(userData.pointAmt.toString()): 0}}</span></li>
            <li class="myg"><img src="@/assets_mobile/img/mygicon.png" /><span>{{thousand(coinAmt ? coinAmt.toString(): '0')}}</span></li>
          </ul>
          <a @click="goPageByName('msgMain')" class="mymsg"><img src="@/assets_mobile/img/message_off.svg" /><span class="mgsn">{{thousand(unReadMessageCount ? unReadMessageCount.toString() : '0')}}</span></a>
        </div>
        <div v-if="!userData">
          <a class="mloginbtn" @click="goPageByName('mobileJoin')">JOIN</a>
          <a class="mloginbtn ml10" @click="goPageByName('mobileLogin')">LOGIN</a>
        </div>
      </div>
    </header> -->
    <header><!--:class="{'login' : userData}"-->
      <!-- <div class="notice">
        <em class="speakerIcon"><img src="@/assets/img/notice_darkGray.png" /></em>
        <span class="" v-if="flowNoticeContent">{{ flowNoticeContent }}</span>
      </div> -->
      <div class="header">
        <div class="sideMenuBtn" @click="openSide()">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1 class="logo"><a @click="goPageByName('mobileMain')"><img src="@/assets/img/play_peanut.svg" /></a></h1>
        <div class="logout" v-if="userData">
          <a class="mloginbtn" @click="signOut"><img src="@/assets/img/icon_logoutw.svg" /></a>
        </div>
      </div>
      <div class="loginWrap" v-if="!userData" :class="{'login' : userData}">
        <a class="mloginbtn" @click="goPageByName('mobileLogin')">로그인</a>
        <a class="mloginbtn fullcolor" @click="goPageByName('mobileJoin')">회원가입</a>
      </div>
      <div class="cashWrap" v-if="userData">
        <ul class="mym">
          <li class="myc"><img src="@/assets_mobile/img/mycicon.png" /><span>{{thousand(userData.cashAmt.toString())}} {{$t('front.common.money')}}</span></li>
          <li class="myp"><img src="@/assets_mobile/img/mypicon.png" /><span>{{thousand(userData.pointAmt.toString())}} P</span></li>
        </ul>
      </div>
    </header>
    <div class="sidemenu" :class="{'on' : sideMenuOn}">
      <button class="sideClose" @click="openSide()">x</button>
      <div class="infowrap" v-if="userData">
        <div class="infotopwrap">
          <div class="infotop">
            <div class="namelv">
              <h3>
                <span class="name">{{userData.memNick}}</span> <template v-if="$i18n.locale === 'ko'">님</template>
                <em class="lv">Level. {{userData.memLevel}}</em>
                <p class="gamelv">
                  <span><em>{{$t('front.gameCategory.livecasino')}}</em>LV.{{userData.casinoLevel}}</span>
                  <span><em>{{$t('front.gameCategory.slot')}}</em>LV.{{userData.slotLevel}}</span>
                </p>
              </h3>
              <span class="medal" :class="getMedalClass(userData.memLevel)"></span>
            </div>
            <ul class="mym">
              <li class="myc"><img src="@/assets_mobile/img/mycicon.png" /><span>{{thousand(userData.cashAmt.toString())}} {{$t('front.common.money')}}</span></li>
              <li class="myp"><img src="@/assets_mobile/img/mypicon.png" /><span>{{thousand(userData.pointAmt.toString())}} P</span></li>
            </ul>
          </div>
        </div>
      </div>
      <!-- <ul class="sideList">
        <li @click="goPagebyMenu('charge')">입금신청</li>
        <li @click="goPagebyMenu('exchange')">출금신청</li>
        <li @click="goPagebyMenu('point')">포인트전환</li>
        <li @click="goPagebyMenu('noticeList')">공지사항</li>
        <li @click="goPagebyMenu('qnaList')">고객센터</li>
        <li @click="goPagebyMenu('betList')">베팅내역</li>
        <li v-if="userData" @click="goPagebyMenu('myinfo')">마이페이지</li>
      </ul> -->
    </div>
  </template>
</template>

<script>

import { mapState } from 'vuex'
import NewMessageMobile from '@/components/member/MessageMobile.vue'
import { getFlowNotice } from '@/api/board'

export default {
  name: 'Header',
  components: { NewMessageMobile },
  props: [
    'type'
  ],
  data () {
    return {
      isOpenLang: false,
      sideMenuOn: false,
      flowNoticeContent: ''
    }
  },
  computed: {
    ...mapState([
      'userData',
      'unReadMessageCount'
    ]),
    isUnReadMessage () {
      return this.unReadMessageCount && this.unReadMessageCount && this.unReadMessageCount !== '0' && this.$route.name.indexOf('message') === -1 && this.$route.name.indexOf('msg') === -1
    }
  },
  watch: {
    userData () {
      if (this.userData) {
        this.onLoadFlowNotice()
      }
    }
  },
  created () {
    this.onLoadFlowNotice()
  },
  methods: {
    openLang () {
      this.isOpenLang = !this.isOpenLang
    },
    openSide () {
      this.sideMenuOn = !this.sideMenuOn
    },
    onLogOut () {
      this.$root.signOut()
      this.$router.push({ name: 'main' })
      // location.reload()
    },
    onLoadFlowNotice () {
      if (this.userData) {
        getFlowNotice({}).then(res => {
          const result = res.data
          if (result.resultCode === '0') {
            this.flowNoticeContent = result.data.content
            console.log(this.flowNoticeContent)
          }
        })
      }
    },
    goPagebyMenu (name) {
      this.sideMenuOn = false
      this.goPageByName(name)
    },
    getMedalClass (level) {
      if (level < 10) {
        return 'medal' + level
      } else {
        return 'medallv'
      }
    }
  }
}
</script>

<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/swiper.min.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/myinfo.css"></style>
<style scoped>
  .cashWrap .mym {padding: 0;}
  .cashWrap .mym li {display: flex; gap: 5px; width: auto;}
  .cashWrap .mym li span {margin-top: 0;}
</style>
