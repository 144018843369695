export const gamelist = {
  name: 'gamelist',
  path: '/gamelist',
  component: () => import('@/views/member/gamelist'),
  redirect: {
    name: 'listcasino'
  },
  meta: {
    layout: 'default',
    text: '게임리스트',
    gnb: false,
    guestOnly: true
  },
  children: [
    {
      name: 'listcasino',
      path: '/gamelist/casino',
      component: () => import('@/views/member/gamelist/casino'),
      meta: {
        layout: 'default',
        text: '카지노'
      }
    },
    {
      name: 'listmini',
      path: '/gamelist/minigame',
      component: () => import('@/views/member/gamelist/minigame'),
      meta: {
        layout: 'default',
        text: '미니게임'
      }
    },
    {
      name: 'listslot',
      path: '/gamelist/slot',
      component: () => import('@/views/member/gamelist/slot'),
      meta: {
        layout: 'default',
        text: '슬롯게임'
      }
    }
  ]
}
